var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"t7xs2B_vGfNlq5ZrpBtVp"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const FILTER_ERROR_MESSAGES = [
  'Loading chunk', // this happens after release and the old version is not available

  // HYDRATION_ERRORS - https://sentry.gymbeam.io/organizations/gymbeam/issues/198860/?project=10
  'Minified React error',
  'Hydration failed',
  'There was an error while hydrating',

  // Not useful to log, instead aim to increase the sample rate as 0.01 is too low
  'No error message', // https://sentry.gymbeam.io/organizations/gymbeam/issues/298047/?project=10
  'Load failed', // https://sentry.gymbeam.io/organizations/gymbeam/issues/187934/?project=10
  'Non-Error promise rejection', // https://sentry.gymbeam.io/organizations/gymbeam/issues/234135/?project=10
  'Failed to fetch', // https://sentry.gymbeam.io/organizations/gymbeam/issues/308861/?project=10
  'Fetch is aborted', // https://sentry.gymbeam.io/organizations/gymbeam/issues/307812/?project=10
  'Invalid Response Status', // https://sentry.gymbeam.io/organizations/gymbeam/issues/313068/?project=10
  'An error occurred in the Server Components render', // https://sentry.gymbeam.io/organizations/gymbeam/issues/172828/?project=10

  // External libraries
  '_AutofillCallbackHandler',
  "reading 'document'",
  'postMessage',
  'event.data.substring is not a function',
  'Java exception was raised during method invocation',
  'null is not an object',
  'undefined is not an object',
  'No currency found!',
  'globalThis is not defined',
]

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 0.01,
  environment: process.env.SENTRY_ENV,
  enabled: process.env.SENTRY_ENV !== 'development',
  beforeSend(event) {
    const isFilteredError = event.exception?.values?.find((exception) => {
      return !!FILTER_ERROR_MESSAGES.find(
        (message) => exception?.value?.indexOf(message) !== -1,
      )
    })

    if (!!isFilteredError) {
      return null
    }

    return event
  },
})
